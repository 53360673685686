module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-page-progress@2.2.1/node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":["/"],"height":2,"prependToBody":false,"color":"#5500FF"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mixpanel@3.6.0_gatsby@5.13.3_react-dom@18.1.0_react@18.1.0/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"bf9a667b4ebcdf01f6e5c1ae581c4eaa"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@4.18.0_gatsby@5.13.3_react-dom@18.1.0_react@18.1.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4Q0KS68L38"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.3_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","icon":"src/images/icon.png","name":"VW Golf MKV 2007 | Speedy hot hatch with Apple CarPlay and Android Auto","short_name":"VW Golf","description":"A mix of responsible and fun, this isn't a V8 petrol guzzler, it is a refined German engineered beats. An efficient 2.0L engine may not seem like much but in a small car it is a joy to drive.","start_url":"/","background_color":"#000","theme_color":"#5500FF","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f11faa8a3c4116d5e48341509bb93338"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_react-dom@18.1.0_react@18.1.0_typescript@4.7.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
